<template>
  <v-snackbar
    top
    right
    v-model="snackmsg.visible"
    style="padding-top: 0px"
    :color="snackmsg.color"
    :timeout="snackmsg.timeout"
    :multi-line="snackmsg.multiline === true"
  >
    <h3>{{ snackmsg.text }}</h3>

    <template v-slot:action="{ attrs }">
      <v-btn fab icon @click="closeSnackbar" v-bind="attrs">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapMutations } from "vuex";

/**
 * This is a global snack msg compoenent
 * 
 * use it by defining a method in your component like this:
 *  showErrorSnack(msg) {
 *    this.$store.commit('snackmsg/show', { text: msg, color: 'error' });
 *  },
 */
export default {
  name: "SnackMsg",
  computed: {
    snackmsg() {
      return this.$store.state.snackmsg;
    }
  },
  methods: {
    closeSnackbar() {
      this.$store.commit("snackmsg/close");
    }
  }
};
</script>

<style scoped></style>
